import React, { useEffect, useMemo, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PdfViewer from "../../InvoiceDetail/InvoiceBody/InvoiceMain/InvoiceView/PdfViewer/PdfViewer";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import {
  formatDate,
  getLastActionWithKey,
  getTimeDate,
  getNotyfObject,
} from "../../../shared/utility";
import {
  EventsItem,
  EventColor,
  EventValue,
  EventDate,
  EventVal,
  EventAction,
  EventMessage,
  DPContainer,
  Rvcadiv,
  CFBody,
  Coselect,
  CFContainer,
  CFFormGroup,
  CFFormSection,
  CFSection,
  FormInput,
  FormTextArea,InputCh,                      
  FormLabel,
} from "./Information.styled";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Axios from "../../../axios-proas";
import DatePicker from "@mui/lab/DatePicker";
import frLocale from "date-fns/locale/fr";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ConfirmationModal from "../../UI/ConfirmationModal/ConfirmationModal";
import TraitementForm from "../../InvoiceDetail/InvoiceBody/InvoiceSide/TraitementForm/TraitementForm";
import EditIcon from '@mui/icons-material/Edit';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { TextareaAutosize } from '@mui/base';
import InvoiceSide from '../../../components/InvoiceDetail/InvoiceBody/InvoiceSide/InvoiceSide';
import CustomConfirmModal from "../../../containers/FAQ/CustomConfirmModal/CustomConfirmModal";
import { CTooltip } from "../../UI/CTooltip/CTooltip";
import AmountDetailModal from "./AmountDetailModal";

function InformationInvoice({
  show,
  modalClosed,
  userProfile,
  userLevel,
  invoiceData, 
  rejectUid,
  refreshTable,
  pdfFile,
  pdfFileName,
  loadingPdf,
  getInvoicePdf,
  userUid,
  statuses
}) {
  const { t } = useTranslation();
  const [dateFacture, setDateFacture] = useState(null);
  const [entitiesOwner, setEntitiesOwner] = useState();
  const [codeSuFilter, setCodeSuFilter] = useState();
  const [ownerGlobalFilter, setOwnerGlobalFilter] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErr00ConfirmModal, setShowErr00ConfirmModal] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const dataForSelect = [
    // { label: "err00 - Forcer validation par AGSRM", value: "err00" },
    { label: "err01 - Document hors scope", value: "err01" },
    { label: "err02 - Fournisseur inconnu", value: "err02" },
    { label: "err03 - Raison sociale incorrecte", value: "err03" },
    { label: "err04 - Numéro de commande manquant", value: "err04" },
    { label: "err04a - N° commande incorrect", value: "err04a" },
    { label: "err04b - Raison sociale non conforme au PO", value: "err04b" },
    {
      label: "err05 - Mentions légales obligatoires manquantes",
      value: "err05",
    },
    { label: "err06 - Facture illisible ou incomplète", value: "err06" },
    { label: "err07 - Facture non équilibrée", value: "err07" },
    { label: "err08 - Etiquette TRS absente", value: "err08" },
    { label: "err09 - Entité hors scope", value: "err09" },
    { label: "err10 - Factures multiples dans un PDF", value: "err10" },
    {
      label: "err11 - Erreur autre (exemple : erreurs multiples)",
      value: "err11",
    },
    { label: "err12 - Problème de TVA intracommunautaire", value: "err12" },
    { label: "err51 - Doublon", value: "err51" },
    { label: "err52 - Faux doublon", value: "err52" },
    { label: "err99 - Corbeille", value: "err99" },
  ];
  const documentCodes = [
    { label: "I-ACH-IVD", value: "I-ACH-IVD" },
    { label: "I-ACH-IVM", value: "I-ACH-IVM" },
    { label: "I-ACHCAR-IVM", value: "I-ACHCAR-IVM" },
    { label: "I-ACHNEN-IVD", value: "I-ACHNEN-IVD" },
    { label: "I-ACHSTT-IVM", value: "I-ACHSTT-IVM" },
    { label: "I-AVO-IVD1", value: "I-AVO-IVD1" },
    { label: "I-AVO-IVM", value: "I-AVO-IVM" },
    { label: "I-AVOCAR-IVM", value: "I-AVOCAR-IVM" },
    { label: "I-AVONEN-IVD", value: "I-AVONEN-IVD" },
    { label: "I-AVOSTT-IVM", value: "I-AVOSTT-IVM" }
  ];
  const [lastAction, setLastAction] = useState(null);
  const [errCode, setErrCode] = useState(null);
  const [suCode, setSuCode] = useState(null);
  const [rejectData, setRejectData] = useState([]);
  const [waitingForCreditNote, setWaitingForCreditNote] = useState();
  const [isErr00, setIsErr00] = useState();
  const [rejectNewData, setRejectNewData] = useState([]);
  const [rejectDataCopie, setRejectDatacopie] = useState([]);
  const [savedForm, setSavedForm] = useState(true);
  const [initialInvoiceData, setInitialInvoiceData] = useState(null);
  const [needToSave, setNeedToSave] = useState(false);
  const navigate = useNavigate();
  const notyf = getNotyfObject();
  const [currencies, setCurrencies] = useState([]);
  const exceptions = useMemo(() => {
    return ["err90", "err91", "err92", "err93", "err94", "err99", "err51", "err01"]
  }, [])

  const [suppliersForFilter, setSuppliersForFilter] = useState([]);
  const [supplierGlobalFilter, setSetSupplierGlobalFilter] = useState([]);
  const [taxAmount, setTaxAmount] = useState(0.0);
  const [msg, setMsg] = useState()

  const [dataChecked, setDataChecked ] = useState({
    number: false,
    issuingDate: false,
    inclusiveTaxAmount: false,
    exclusiveTaxAmount: false,
    codeSu: false,
    entity: false,
    codeClient: false,
    CodeTiers: false,
    codeSupplier: false,
    supplier:false,
    errType:false,
    taxAmount:false,
    currency:false,
    yearPeriod:false,
    orderNumber:false,
    documentCode:false
  });
  useEffect(() => {
    setRejectNewData([]);
    if (show) {
      if (currencies?.length === 0)
        Axios.get("currency").then(response => {
          setCurrencies(response.data?.[0]);
        });
      loadownerForFilter("");
      loadSuppliersForFilter("");
      if (rejectUid) {
        Axios.get("/reject/" + rejectUid + "/rejectData")
          .then((res) => {
            setRejectData(res.data);
            setRejectDatacopie(JSON.parse(JSON.stringify(res.data)));
            setWaitingForCreditNote(res.data?.waitingForCreditNote==1)
            setIsErr00(+res.data?.error00==1)
          })
          .catch((err) => {});
        } else if (invoiceData) {
          setRejectData(invoiceData)
          setRejectDatacopie(JSON.parse(JSON.stringify(invoiceData)));
          setIsErr00(+invoiceData?.error00==1)
        }

        return () => {
          setDataChecked({
            number: false,
            issuingDate: false,
            inclusiveTaxAmount: false,
            exclusiveTaxAmount: false,
            codeSu: false,
            entity: false,
            codeClient: false,
            CodeTiers: false,
            codeSupplier: false,
            supplier:false,
            errType:false,
            taxAmount:false,
            currency:false,
            yearPeriod:false,
            orderNumber:false,
            documentCode:false
          })
        };
    } else {
      setRejectData(null);
      setRejectDatacopie(null);
      setLastAction(null);
    }

  }, [invoiceData, rejectUid, show])

  useEffect(() => {
    if (invoiceData?.invoiceUid) {
      Axios.get("/user/" + invoiceData?.invoiceUid + "/getEventForInvoice")
        .then((res) => {
          setLastAction(res.data?.events[0]);
        })
        .catch((err) => {});
      getInvoicePdf(invoiceData?.invoiceUid, "pdf");
    }
  }, [invoiceData?.invoiceUid])

  useEffect(() => {
    setErrCode(rejectData?.rejectTypes);
    setSuCode(rejectData?.codeClient);
    setCodeSuFilter(rejectData?.codeClient);
    setTaxAmount(rejectData?.taxAmount);
   // setOwnerGlobalFilter(rejectData?.clientName);
  }, [rejectData]);

  useEffect(() => {
    if (rejectData && savedForm)
      setInitialInvoiceData({...rejectData})
  }, [rejectData?.invoiceUid, savedForm])

  useEffect(() => {
    setNeedToSave(!_.isEqual(rejectData, initialInvoiceData));
  }, [rejectData, initialInvoiceData])

  console.log("userProfile",userProfile)
  console.log(" (userUid === rejectData?.managerUid || !rejectData?.managerUid)", (userUid === rejectData?.managerUid || !rejectData?.managerUid))

 



  const getInvoiceEventMessage = (event) => {
    switch (event) {
      case "USER_CHAT_EVENT":
        return "Echange par message";
      case "MAIL_EXCHANGE_EVENT":
        return "Echange par mail";
      case "CALL_EXCHANGE_EVENT":
        return "Echange par téléphone";
      case "OTHER_EXCHANGE_EVENT":
        return "Echange";
      case "CALL_OUT_EXCHANGE_EVENT":
        return "Echange par téléphone";
      case "OTHER_OUT_EXCHANGE_EVENT":
        return "Echange ";
      case "MAIL_OUT_EXCHANGE_EVENT":
        return "Echange par mail";
      default:
    }
  };

  const errorCodeChangeHandler = (e) => {
    setErrCode(e?.value);
    setRejectData({ ...rejectData, rejectTypes: e?.value});
  };

    const currencyChangeHandler = (e) => {
      setRejectData({ ...rejectData, currency: e?.value});
    };

    const handleWaitingForCreditNote = (e) => {
        setWaitingForCreditNote(e?.target?.checked)
    }

    const handleSetErr00 = (e) => {
      if (!+invoiceData?.codaRefused) 
        setIsErr00(e?.target?.checked)
      else
        setShowErr00ConfirmModal(e);
    }

  const confirmSetErr00 = () => {
    return new Promise((resolve, reject) => {
      Axios.put(`invoice/${invoiceData?.invoiceUid}/err00`, !showErr00ConfirmModal?.target?.checked).then(response => {
        if (response.data.success) {
          notyf.success("Succès du passage en erreur de vidéocodage");
          setIsErr00(response.data.checked)
          refreshTable();
          resolve(true);
        } else {
          notyf.error("Erreur lors du passage en erreur de vidéocodage");
          reject(false);
        }
      }).catch(() => {
        notyf.error("Échec du passage en erreur de vidéocodage");
        reject(false);
      });
    });
  }

  const handleSaveCodes = () => {
    return new Promise((resolve, reject) => {
      setSavedForm(false);
      Axios.put("reject/"+rejectUid+"/errorCode", {newSuCode : suCode ? suCode: null, errorCode : errCode, waitingForCreditNote: waitingForCreditNote, error00: isErr00, newData : rejectNewData, message : msg }).then(response => {
        if (response.data.success) {
          refreshTable();
          notyf.success("Succès de la sauvegarde");
          setSavedForm(true);
          resolve(true);
        } else {
          notyf.error("Erreur lors de la sauvegarde");
          reject(false);
        }
      }).catch(() => {
        notyf.error("Échec de la sauvegarde");
        reject(false);
      });
    });
  };

  const entityAWChangeHandler = (e) => {
    if( e.value !=='' ){
        setRejectData({ ...rejectData, clientUid: e.value, clientName: e.name , codeClient: e.code });
        setOwnerGlobalFilter(e);
    }else{
        setOwnerGlobalFilter([])
    }

  };
  const entityAWChangeHandlerSupplier = (e) => {
    if( e.value !=='' ){
        setRejectData({ ...rejectData, supplierUid: e.value, supplierName: e.name , codeSupplier: e.code});
        setSetSupplierGlobalFilter(e);
    }else{
        setSetSupplierGlobalFilter([])
    }

  };
  
  const handleDateChange = (date) => {
    if (date != null && date?.toString() !== "Invalid Date") {
      setDateFacture({ date });
      setRejectData({ ...rejectData, issuingDate: date?.getTime() });
    
    }
  };
  const parametersChangeHandler = (e) => {
    const { name, value } = e.target;

    setRejectData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

  
  };


const loadownerForFilter = input => {
  Axios.get("/entity//ownerForFilter?searchFilters="+input ).then(response => {
      setEntitiesOwner([{label: '-- Sélectionnez une entité --', value: ''}].concat(response?.data?.map(s => ({value: s.uid, label: s.code + " " + s.name, code: s.code, name:s.name, startCode: s.startCode}))));
  }).catch(err => {
      setEntitiesOwner([]);
  })
}

const loadSuppliersForFilter = input => {
  Axios.get("/entity//suppliersForFilter?searchFilters="+input ).then(response => {
      setSuppliersForFilter([{label: '-- Sélectionnez un fournisseur --', value: ''}].concat(response?.data?.map(s => ({value: s.uid, label: s.code + " " + s.name, code: s.code, name:s.name, startCode: s.startCode}))));
  }).catch(err => {
      setSuppliersForFilter([]);
  })
}

useEffect(() => {
    let u=[
        "number",
        "issuingDate", 
        "supplierName",
        "codeSupplier",
        "codeClient",
        "clientName",
        "rejectTypes",
        "inclusiveTaxAmount", 
        "exclusiveTaxAmount", 
        "taxAmount", 
        "taxDetails", 
        "currency", 
        "yearPeriod",
        "orderNumber",
        "documentCode"
    ]
    let message = '';
    u.map((item)=>{
        if(rejectNewData[item]){
            if(item==="issuingDate"){
                message = message + item + " : " + (rejectDataCopie[item] ? formatDate(+rejectDataCopie[item]) : '[vide]') + " => " + formatDate(+rejectNewData[item])  + "\r\n"  
            } else if (item==="taxDetails") {
              message = message + item + " :\r\n";
              (rejectDataCopie[item] || [{taxCode: "[vide]", taxValue: "[vide]"}]).forEach(i => {
                message+= "    - " + i.taxCode + " / " + i.taxValue + "\r\n";
              })
              message+= "  =>\r\n";
              (rejectNewData[item] || [{taxCode: "[vide]", taxValue: "[vide]"}]).forEach(i => {
                message+= "    - " + i.taxCode + " / " + i.taxValue + "\r\n";
              })
            } else
            message = message + item + " : " + (rejectDataCopie[item] ? rejectDataCopie[item] : '[vide]') + " => " + rejectNewData[item]  + "\r\n"
        }
        setMsg(message)
    })

  }, [rejectNewData,dataChecked]);

const validateBoxChangeHandler = (name) => {
    if(rejectDataCopie[name]!==rejectData[name]) {
        if (typeof(rejectData[name]) === "string") {
          setRejectNewData((prevState) => ({...prevState,[name]: rejectData[name].trim()}));
          setRejectData((prevState) => ({...prevState, [name]: rejectData[name].trim()}))
        } else
          setRejectNewData((prevState) => ({...prevState,[name]: rejectData[name]}));
      } else {
        if(rejectNewData[name]){
            let tmp = rejectNewData
            delete tmp[name]
            setRejectNewData(tmp)
        }
    }
    setDataChecked({...dataChecked, [name]: false})
}

const checkBoxChangeHandler = (event) => {
    setDataChecked({...dataChecked, [event]: true})
}
const closeBoxChangeHandler = (event) => {
    let temp = rejectNewData
    delete temp[event]
    setRejectNewData(temp)
    setDataChecked({...dataChecked, [event]: false})
    setRejectData({...rejectData, [event]: rejectDataCopie[event]})
   
}

const closeBoxChangeHandlerCodeSu = (event) => {
    let temp = rejectNewData
    delete temp["codeClient"]
    delete temp["clientName"]
    delete temp["clientUid"]
    setRejectNewData(temp)
    setRejectData({...rejectData, codeClient: rejectDataCopie.codeClient,
                                    clientName: rejectDataCopie.clientName,
                                    clientUid: rejectDataCopie.clientUid})
    setDataChecked({...dataChecked, [event]: false})

}

const closeBoxChangeHandlerCodeTiers = (event) => {
    let temp = rejectNewData
    delete temp["codeSupplier"]
    delete temp["supplierName"]
    delete temp["supplierUid"]
    setRejectNewData(temp)

    setRejectData({...rejectData,codeSupplier: rejectDataCopie.codeSupplier,
                                supplierName: rejectDataCopie.supplierName,
                                supplierUid: rejectDataCopie.supplierUid})
    setDataChecked({...dataChecked, [event]: false})
                            }

const validateBoxChangeHandlerSu= (name) => {
    setRejectNewData((prevState) => ({
        ...prevState,
        clientUid: rejectData["clientUid"],
        clientName: rejectData["clientName"],
        codeClient: rejectData["codeClient"]}));
    
    
        setDataChecked({...dataChecked, [name]: false})

       
}   

const validateBoxChangeHandlerCodeSupplier= (name) => {
    setRejectNewData((prevState) => ({
        ...prevState,
        supplierUid: rejectData["supplierUid"],
        supplierName: rejectData["supplierName"],
        codeSupplier: rejectData["codeSupplier"]}));
     
    
        setDataChecked({...dataChecked, [name]: false})
}

  const roundNumber = number => {
    return Math.round(number * 100) / 100;
  }

  const handleSaveForm = (taxDetails, totalTaxValue) => {
    const totalExclusiveTaxAmount = roundNumber(taxDetails.map(td => +td.taxValue).reduce((sum, value) => sum + value, 0));
    setRejectNewData({...rejectNewData, taxDetails, taxAmount: totalTaxValue, exclusiveTaxAmount: totalExclusiveTaxAmount, inclusiveTaxAmount: roundNumber(totalExclusiveTaxAmount + totalTaxValue)})
    setRejectData({...rejectData, taxDetails, taxAmount: totalTaxValue, exclusiveTaxAmount: totalExclusiveTaxAmount, inclusiveTaxAmount: roundNumber(totalExclusiveTaxAmount + totalTaxValue)})
  }

  return (
    <Modal show={show} onHide={modalClosed} dialogClassName="dialogModal" contentClassName="modal_w_mc">
      <Modal.Header closeButton>
        <Modal.Title>Information facture</Modal.Title>
      </Modal.Header>
      <Modal.Body className="ADMCreationModal__body" style={{ width: "86vw" }}>
        <CFContainer>
          <CFBody>
            <CFSection style={{width: "30%"}}>
              <CFFormSection>
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!rejectData?.number ? {color: "red"} : null}>Numéro de facture : </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                  
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.number || ""}
                    onChange={parametersChangeHandler}
                    maxLength={255}
                    name="number"
                    disabled={!dataChecked?.number}
                  />
                  {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.number?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("number")}/>:<>
                  <CTooltip title="Cliquer pour valider le changement de numéro de facture"><span><CheckIcon id="number" name="number" style={{cursor: "pointer", color: "green"}} onClick={(e) => validateBoxChangeHandler("number")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de numéro de facture"><span><CloseIcon id="number" name="number"style={{cursor: "pointer", color: "red"}} onClick={(e) => closeBoxChangeHandler("number")}/></span></CTooltip></>)}
              

                </CFFormGroup>
                {["REJECT_MANAGER", "ADMIN"].includes(userProfile) && userLevel === "admin" && <CFFormGroup>
                  <FormLabel htmlFor="documentCode" style={!documentCodes?.find(c => c.value === rejectData?.documentCode) ? {color: "red"} : null}>Code journal :</FormLabel>
                  {dataChecked?.documentCode? (<>
                    <Coselect
                      name="documentCode"
                      value={documentCodes.find(c => c.value === rejectData?.documentCode) || ""}
                      onChange={e => setRejectData({ ...rejectData, documentCode: e?.value})}
                      placeholder={"Choisir un code journal"}
                      noOptionsMessage={() => "Aucun résultat"}
                      onSelect={e => setRejectData({ ...rejectData, documentCode: e?.value})}
                      options={documentCodes}
                    />
                    
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <>
                    <CTooltip title="Cliquer pour valider le changement code journal"><span><CheckIcon id="documentCode" name="documentCode"style={{cursor: "pointer", color: "green"}} onClick={(e) => validateBoxChangeHandler("documentCode")}/></span></CTooltip>
                    <CTooltip title="Cliquer pour annuler le changement code journal"><span><CloseIcon id="documentCode" name="documentCode" style={{cursor: "pointer", color: "red"}} onClick={(e) => closeBoxChangeHandler("documentCode")}/></span></CTooltip></>}
                  </>) : (<>
                    <FormInput
                      id="documentCode"
                      type="text"
                      placeholder="Information obligatoire CODA"
                      defaultValue={rejectData?.documentCode || ""}
                      maxLength={255}
                      name="documentCode"
                      disabled={true}
                    />
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <EditIcon style={{cursor: "pointer"}} onClick={(e) => checkBoxChangeHandler("documentCode")}/>}
                  </>)}
                </CFFormGroup>}

                <CFFormGroup>
                <FormLabel htmlFor="title" style={!rejectData?.issuingDate ? {color: "red"} : null}>Date facture :</FormLabel>
                <div>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={frLocale}
                  >
                  
                      <DatePicker
                        className="extractionForm__datepicker"
                        label=""
                        format="dd/MM/yyyy"
                        value={+rejectData?.issuingDate}
                        name="issuingDate"
                        onChange={handleDateChange}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder:"Information obligatoire CODA"
                              }}
                            />
                          );
                        }}
                        disabled={!dataChecked?.issuingDate}
                      />
                  
                  </LocalizationProvider>
             


                  {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.issuingDate?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("issuingDate")}/>:<>
                  <CTooltip title="Cliquer pour valider le changement de date de facture"><span><CheckIcon id="issuingDate" name="issuingDate" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandler("issuingDate")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de date de facture"><span><CloseIcon id="issuingDate" name="issuingDate"style={{cursor: "pointer", color: "red"}}   onClick={(e) => closeBoxChangeHandler("issuingDate")}/></span></CTooltip></>)}
                   </div>
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!rejectData?.codeClient && !rejectData?.suCode ? {color: "red"} : null}>Code SU : </FormLabel>
                  {dataChecked?.codeClient &&
                  !rejectData?.rejectTypes?.includes("err9") ? (<>
                    <Coselect
                 
                      name="errType"
                      value={ownerGlobalFilter}
                      onChange={(e) => entityAWChangeHandler(e)}
                      onInputChange={input => loadownerForFilter(input)}
                      noOptionsMessage={() => "Aucun résultat"}
                     // isClearable={true}
                      // onInputChange={suCodeInputChangeHandler}
                     // isRtl={true}
                    //   styles={{
                    //     control: (provided) => ({ ...provided, width: "80%" }),
                    //     singleValue: (provided) => ({
                    //       ...provided,
                    //       color: suCode !== rejectData?.codeClient ? "red" : "",
                    //     }),
                    //     option: (provided, state) => ({
                    //       ...provided,
                    //       color:
                    //         state.value !== rejectData?.codeClient ? "red" : "",
                    //     }),
                    //   }}
                      options={entitiesOwner}
                      isDisabled={!dataChecked?.codeClient}
                    />
                   {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.codeClient?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("issuingDate")}/>:<>
                   <CTooltip title="Cliquer pour valider le changement d'entité"><span><CheckIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerSu("codeClient")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement d'entité"><span><CloseIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeSu("codeClient")}/> </span></CTooltip></>)}    
                     </>
                  ) : (<>
                    <FormInput
                      id="title"
                      type="text"
                      placeholder="Information obligatoire CODA"
                      value={rejectData?.codeClient||rejectData?.suCode||""} 
                      maxLength={255}
                      name="codeClient"
                      disabled={!dataChecked?.codeClient}
                    />
                     {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.codeClient?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("codeClient")}/>:<>
                     <CTooltip title="Cliquer pour valider le changement d'entité"><span><CheckIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerSu("codeClient")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement d'entité"><span><CloseIcon id="codeClient" name="codeClient" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeSu("codeClient")}/></span></CTooltip></>)}
                    </>
                  )}
                </CFFormGroup>
              
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!rejectData?.clientName && !rejectData?.ownerName ? {color: "red"} : null}>Entité : </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.clientName || rejectData?.ownerName || ""}
                    maxLength={255}
                    name="entity"
                    disabled
                  />

                </CFFormGroup>

               
            <CFFormGroup>
             <FormLabel htmlFor="title" style={!rejectData?.codeSupplier && !rejectData?.supplierCode ? {color: "red"} : null}>Code tiers :</FormLabel>
             {dataChecked?.codeSupplier ? (<>
                  <Coselect
                    name="codeSupplier"
                    noOptionsMessage={() => "Aucun résultat"}
                    placeholder={"Sélectionnez un fournisseur"}
                    options={suppliersForFilter}
                    onInputChange={input => loadSuppliersForFilter(input)}
                    value={supplierGlobalFilter}
                    onChange={(e) => entityAWChangeHandlerSupplier(e)}
                  />
                {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.codeSupplier?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("codeSupplier")}/>:<>
                <CTooltip title="Cliquer pour valider le changement de fournisseur"><span><CheckIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerCodeSupplier("codeSupplier")}/></span></CTooltip>
                <CTooltip title="Cliquer pour annuler le changement de fournisseur"><span><CloseIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeTiers("codeSupplier")}/></span></CTooltip></>)}
                </>)
                :<>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.codeSupplier || rejectData?.supplierCode || ""}
                    maxLength={255}
                    name="supplier"
                    disabled={!dataChecked?.codeSupplier}
                    />
                   {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.codeSupplier?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("codeSupplier")}/>:<>
                <CTooltip title="Cliquer pour valider le changement de fournisseur"><span><CheckIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "green"}}  onClick={(e) => validateBoxChangeHandlerCodeSupplier("codeSupplier")}/></span></CTooltip>
                <CTooltip title="Cliquer pour annuler le changement de fournisseur"><span><CloseIcon id="codeSupplier" name="codeSupplier" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandlerCodeTiers("codeSupplier")}/></span></CTooltip></>)}
            </>
             }
                </CFFormGroup>
            
                <CFFormGroup>
                  <FormLabel htmlFor="title" style={!rejectData?.supplierName ? {color: "red"} : null}>Nom fournisseur :</FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.supplierName|| ""}
                    maxLength={255}
                    name="supplier"
                    disabled
                  />

                </CFFormGroup>
                {(rejectData?.rejectTypes || +invoiceData?.codaRefused) && <CFFormGroup>
                  <FormLabel htmlFor="title">Type rejet :</FormLabel>
                  {dataChecked?.rejectTypes? (<>
                    <Coselect
                      name="rejectTypes"
                      value={
                        dataForSelect.filter((o) => o.value === errCode) || ""
                      }
                      onChange={errorCodeChangeHandler}
                      placeholder={"Choisir un code erreur"}
                      noOptionsMessage={() => "Aucun résultat"}
                      // suggestions={emailTypesSuggestions}
                      onSelect={errorCodeChangeHandler}
                      // isLoading={!email?.mailType? true:false}
                    //   isClearable={true}
                    //   isRtl={true}
                    //   styles={{
                    //     control: (provided) => ({ ...provided, width: "80%" }),
                    //     singleValue: (provided) => ({
                    //       ...provided,
                    //       color:
                    //         errCode !== rejectData?.rejectTypes ? "red" : "",
                    //     }),
                    //     option: (provided, state) => ({
                    //       ...provided,
                    //       color:
                    //         state.value !== rejectData?.rejectTypes
                    //           ? "red"
                    //           : "",
                    //     }),
                    //   }}
                      options={dataForSelect}
                      isDisabled={!dataChecked?.rejectTypes}
                    />
                    {userProfile=="REJECT_MANAGER" && (userUid === rejectData?.managerUid || !rejectData?.managerUid) && <>
                    <CTooltip title="Cliquer pour valider le changement de type de rejet"><span><CheckIcon id="rejectTypes" name="rejectTypes" style={{cursor: "pointer", color: "green"}} onClick={(e) => validateBoxChangeHandler("rejectTypes")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de type de rejet"><span><CloseIcon id="rejectTypes" name="rejectTypes"style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("rejectTypes")}/> </span></CTooltip></>}
                    </>
                  ) : (<>
                    <FormInput
                      id="title"
                      type="text"
                      placeholder=""
                      defaultValue={rejectData?.rejectTypes || ""}
                      maxLength={255}
                      name="title"
                      disabled={true}
                    />
                      {userProfile=="REJECT_MANAGER" && !(+invoiceData?.codaRefused && +invoiceData?.codaRefusedArchived) && (userUid === rejectData?.managerUid || !rejectData?.managerUid) ? <EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("rejectTypes")}/>:null}
                    </>
                  )}
                </CFFormGroup>}

                {/* <CFFormGroup>
                  <FormLabel htmlFor="inclusiveTaxAmount" style={!rejectData?.inclusiveTaxAmount ? {color: "red"} : null}>Montant TTC :
                  </FormLabel>
                  <FormInput
                    type="text"
                    name="inclusiveTaxAmount"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.inclusiveTaxAmount}
                    onChange={parametersChangeHandler}
                    disabled={!dataChecked?.inclusiveTaxAmount}
                  />
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.inclusiveTaxAmount?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("inclusiveTaxAmount")}/>:<>
                    <CTooltip title="Cliquer pour valider le changement de montant TTC"><span><CheckIcon id="inclusiveTaxAmount" name="inclusiveTaxAmount" style={{cursor: "pointer", color: "green"}} onClick={(e) => validateBoxChangeHandler("inclusiveTaxAmount")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de montant TTC"><span><CloseIcon id="inclusiveTaxAmount" name="inclusiveTaxAmount"style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("inclusiveTaxAmount")}/></span></CTooltip></>)}

                </CFFormGroup>
                <CFFormGroup>
                  <FormLabel htmlFor="exclusiveTaxAmount" style={!rejectData?.exclusiveTaxAmount ? {color: "red"} : null}>Montant HT :</FormLabel>
                  <FormInput
                    type="text"
                    name="exclusiveTaxAmount"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.exclusiveTaxAmount}
                    onChange={parametersChangeHandler}
                     disabled={!dataChecked?.exclusiveTaxAmount}
                  />
                     {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.exclusiveTaxAmount?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("exclusiveTaxAmount")}/>:<>
                     <CTooltip title="Cliquer pour valider le changement de montant HT"><span><CheckIcon id="exclusiveTaxAmount" name="exclusiveTaxAmount"style={{cursor: "pointer", color: "green"}}   onClick={(e) => validateBoxChangeHandler("exclusiveTaxAmount")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de montant HT"><span><CloseIcon id="exclusiveTaxAmount" name="exclusiveTaxAmount" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("exclusiveTaxAmount")}/></span></CTooltip></>)}


                </CFFormGroup> */}

                <CFFormGroup>
                  <FormLabel htmlFor="inclusiveTaxAmount">Montant TTC :</FormLabel>
                  <FormInput
                    type="text"
                    name="inclusiveTaxAmount"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.inclusiveTaxAmount}
                    disabled={true}
                  />
                  {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <EditIcon style={{cursor: "pointer"}} onClick={() => setShowAmountModal(true)}/>}
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="exclusiveTaxAmount">Montant HT :</FormLabel>
                  <FormInput
                    type="text"
                    name="exclusiveTaxAmount"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.exclusiveTaxAmount}
                    disabled={true}
                  />
                  {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <EditIcon style={{cursor: "pointer"}} onClick={() => setShowAmountModal(true)}/>}
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="taxAmount">Montant taxes :</FormLabel>
                  <FormInput
                    type="text"
                    name="taxAmount"
                    placeholder="Information obligatoire CODA"
                    value={taxAmount}
                    disabled={true}
                  />
                  {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <EditIcon style={{cursor: "pointer"}} onClick={() => setShowAmountModal(true)}/>}
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="currency" style={!currencies?.find(c => c.code === rejectData?.currency) ? {color: "red"} : null}>Devise :</FormLabel>
                  {dataChecked?.currency? (<>
                    <Coselect
                      name="currency"
                      value={currencies?.map(c => ({label: c.label, value: c.code})).find(c => c.value === rejectData?.currency) || ""}
                      onChange={currencyChangeHandler}
                      placeholder={"Choisir une devise"}
                      noOptionsMessage={() => "Aucun résultat"}
                      onSelect={currencyChangeHandler}
                      options={currencies?.map(c => ({label: c.label, value: c.code}))}
                    />
                    
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <>
                    <CTooltip title="Cliquer pour valider le changement de devise"><span><CheckIcon id="currency" name="currency"style={{cursor: "pointer", color: "green"}}   onClick={(e) => validateBoxChangeHandler("currency")}/></span></CTooltip>
                    <CTooltip title="Cliquer pour annuler le changement de devise"><span><CloseIcon id="currency" name="currency" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("currency")}/></span></CTooltip></>}
                  </>) : (<>
                    <FormInput
                      id="currency"
                      type="text"
                      placeholder="Information obligatoire CODA"
                      defaultValue={currencies.find(c => rejectUid ? c.code === rejectData?.currency : c.code === invoiceData?.currency)?.label || ""}
                      maxLength={255}
                      name="currency"
                      disabled={true}
                    />
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("currency")}/>}
                  </>)}
                </CFFormGroup>

                <CFFormGroup>
                  <FormLabel htmlFor="orderNumber">Numéro de commande :</FormLabel>
                  <FormInput
                    type="text"
                    name="orderNumber"
                    value={rejectData?.orderNumber || ""}
                    onChange={parametersChangeHandler}
                    disabled={!dataChecked?.orderNumber}
                  />
                    {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.orderNumber ? <EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("orderNumber")}/>:<>
                    <CTooltip title="Cliquer pour valider le changement de numéro de commande"><span><CheckIcon name="orderNumber"style={{cursor: "pointer", color: "green"}}   onClick={(e) => validateBoxChangeHandler("orderNumber")}/></span></CTooltip>
                  <CTooltip title="Cliquer pour annuler le changement de numéro de commande"><span><CloseIcon name="orderNumber" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("orderNumber")}/></span></CTooltip></>)}
                </CFFormGroup>

                {["REJECT_MANAGER", "ADMIN"].includes(userProfile) && userLevel === "admin" && <CFFormGroup>
                  <FormLabel htmlFor="yearPeriod" style={!rejectData?.yearPeriod ? {color: "red"} : null}>Période :</FormLabel>
                  <FormInput
                    type="text"
                    name="yearPeriod"
                    placeholder="Information obligatoire CODA"
                    value={rejectData?.yearPeriod || ""}
                    onChange={parametersChangeHandler}
                     disabled={!dataChecked?.yearPeriod}
                  />
                     {userProfile=="REJECT_MANAGER" && userUid === rejectData?.managerUid && !exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && (!dataChecked?.yearPeriod?<EditIcon style={{cursor: "pointer"}}  onClick={(e) => checkBoxChangeHandler("yearPeriod")}/>:<>
                     <CTooltip title="Cliquer pour valider le changement de période"><span><CheckIcon id="yearPeriod" name="yearPeriod"style={{cursor: "pointer", color: "green"}}   onClick={(e) => validateBoxChangeHandler("yearPeriod")}/></span></CTooltip>
                   <CTooltip title="Cliquer pour annuler le changement de période"><span><CloseIcon id="yearPeriod" name="yearPeriod" style={{cursor: "pointer", color: "red"}}  onClick={(e) => closeBoxChangeHandler("yearPeriod")}/></span></CTooltip></>)}
                </CFFormGroup>}
               

                <CFFormGroup>
                  <FormLabel htmlFor="title">
                    Auteur de la dernière action :
                  </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                 
                    cc
                    defaultValue={
                      getLastActionWithKey(rejectData?.lastActionAuthor) || ""
                    }
                    maxLength={255}
                    name="title"
                    disabled
                  />
                </CFFormGroup>
                <CFFormGroup>
                  <FormLabel htmlFor="title">
                    Responsable actuel du rejet :
                  </FormLabel>
                  <FormInput
                    id="title"
                    type="text"
                    placeHolder="Titre"
                    title={(rejectData?.managerLogin) || ""}
                    defaultValue={
                      (rejectData?.managerLogin ) || ""
                    }
                    maxLength={255}
                    name="title"
                    disabled
                  />
                </CFFormGroup>
                  {!exceptions.includes(initialInvoiceData?.rejectTypes) && !+invoiceData?.codaRefused && <CFFormGroup>
                      <FormLabel htmlFor="waitingForCreditNote">Demande d'avoir :</FormLabel>
                      <FormCheckInput
                          onChange={handleWaitingForCreditNote}
                          checked={waitingForCreditNote}
                          disabled={userProfile !== "REJECT_MANAGER" || userUid !== rejectData?.managerUid}
                      />
                  </CFFormGroup>}
                  <CFFormGroup>
                      <FormLabel htmlFor="waitingForCreditNote">Erreur de vidéocodage (err00) :</FormLabel>
                      <FormCheckInput
                          onChange={handleSetErr00}
                          checked={isErr00}
                          disabled={userProfile !== "REJECT_MANAGER" || userUid !== rejectData?.managerUid && rejectData?.managerUid}
                        />
                  </CFFormGroup>
                  {userProfile=="REJECT_MANAGER" && (userUid === rejectData?.managerUid || !rejectData?.managerUid) && <CFFormGroup style={{alignItems: "center"}}>
                      <TraitementForm 
                          refreshTable={refreshTable} 
                          showRVCA={!exceptions.includes(initialInvoiceData?.rejectTypes)} 
                          saveCodesHandler={handleSaveCodes} 
                          invoiceData={rejectData} 
                          invoiceNewData={rejectNewData} 
                          restoreReject={() => {if(rejectData?.processingStatus === "TREATED") restoreReject()}} 
                          waitingForCreditNote={waitingForCreditNote}
                          message = {msg}
                          initialInvoiceData={initialInvoiceData}
                          closeModal={modalClosed}
                          setMsg = {setMsg}
                          needToSave={needToSave}
                          userProfile={userProfile}
                          userLevel={userLevel}
                      />
                  </CFFormGroup>}
                {lastAction && (
                  <>
                    <CFFormGroup>
                      <FormLabel htmlFor="title">Dernière action :</FormLabel>
                    </CFFormGroup>

                    <CFFormGroup>
                      <EventsItem>
                        <EventColor color={"red"} />
                        <EventValue>
                          <EventVal>
                            {getInvoiceEventMessage(lastAction?.type)}
                          </EventVal>
                          <EventMessage title={lastAction?.message}>
                            {lastAction?.message}
                          </EventMessage>
                          <EventDate>
                            {formatDate(+lastAction?.creationDate) +
                              " " +
                              getTimeDate(+lastAction?.creationDate)}
                          </EventDate>
                        </EventValue>
                        <EventAction
                          onClick={() => navigate("/invoiceDetail/" + invoiceData?.invoiceUid)}
                        >
                          <span>voir</span>
                          <ArrowForwardIosIcon />
                        </EventAction>
                      </EventsItem>
                    </CFFormGroup>
                     
                  </>
                )}
              </CFFormSection>
            </CFSection>
            <CFSection style={{width: "44%"}}>
              {loadingPdf ? <Spinner /> : <PdfViewer pdfFile={pdfFile} pdfFileName={pdfFileName} />}
            </CFSection>
            <CFSection style={{width: "25%"}}>
             <InvoiceSide width="100%" statuses={statuses}/> 
            </CFSection>
          </CFBody>
        </CFContainer>
      </Modal.Body>
      <ConfirmationModal
        show={showConfirmationModal}
        modalClosed={() => setShowConfirmationModal(false)} 
        confirm={() => setShowConfirmationModal(false)}
        cancel={() => setShowConfirmationModal(false)}
        title={"Confirmation d'envoi en RVCA"}
        message={`Êtes-vous sûr de vouloir renvoyer cette facture en vidéocodage avec les données saisies ?`}
      />
      <CustomConfirmModal 
        show={showErr00ConfirmModal}
        handleClose={() => setShowErr00ConfirmModal(false)}
        text={`Êtes-vous sûr de vouloir déclarer ce refus comme erreur de vidéocodage ?`}
        okCallback={confirmSetErr00}/>
      <AmountDetailModal
        show={showAmountModal}
        modalClosed={() => setShowAmountModal(false)}
        rejectData={rejectData}
        roundNumber={roundNumber}
        saveFormHandler={handleSaveForm} />
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  loadingPdf: state.invoiceDetail.loadingPdf,
  invoiceData: state.invoiceDetail.invoiceData,
  pdfFile: state.invoiceDetail.pdfFile?.pdfFile || null,
  pdfFileName: state.invoiceDetail?.pdfFile?.docFile || "facture.pdf",
  userUid: state.auth.userUid,
  userLevel: state.auth.userLevel
});
const mapDispatchToProps = (dispatch) => ({
  getInvoicePdf: (invoiceUid, type) =>
    dispatch(actions.getInvoicePdf(invoiceUid, type))
});
export default connect(mapStateToProps, mapDispatchToProps) (InformationInvoice);
